import React, { PureComponent } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavLink as StrapLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { matchPath, withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

library.add(faArchive);

class MyNavbar extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { location } = this.props;
    const match = matchPath(location.pathname, {
      path: "/archives"
    });

    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand tag={NavLink} to="/" className="mr-auto">
            JeuForum.fr
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <StrapLink to="/nephilim" tag={NavLink}>
                  Nephilim
                </StrapLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar active={null !== match}>
                <DropdownToggle nav caret>
                  <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                  Archives
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem to="/archives/vegas" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives de Vegas
                  </DropdownItem>
                  <DropdownItem to="/archives/rome" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives de Rome
                  </DropdownItem>
                  <DropdownItem to="/archives/2666" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives de 2666
                  </DropdownItem>
                  <DropdownItem to="/archives/orgone" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives d’Orgone
                  </DropdownItem>
                  <DropdownItem to="/archives/vegas-elevation" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives de Vegas-Elevation
                  </DropdownItem>
                  <DropdownItem to="/archives/gehinnom" tag={NavLink}>
                    <FontAwesomeIcon icon={"archive"} className={"mr-1"} />
                    Archives de Gehinnom
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

MyNavbar.propTypes = {};

export default withRouter(MyNavbar);
