import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { NavLink } from "react-router-dom";

const ArchivesListGroup = () => {
  return (
    <ListGroup>
      <ListGroupItem color={"dark"}>
        <strong>Archives de forum</strong>
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/vegas">
        Vegas 2013
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/rome">
        Rome
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/2666">
        2666
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/orgone">
        Orgone
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/vegas-elevation">
        Vegas-Elevation
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/archives/gehinnom">
        Gehinnom
      </ListGroupItem>
    </ListGroup>
  );
};

export default ArchivesListGroup;
