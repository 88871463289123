import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink } from "@fortawesome/free-solid-svg-icons";

library.add(faLink);

const IdentiteTable = (props) => {
  const { avatar, lines, link, members, pegi, period, title, type } = props;

  return (
    <Table bordered size="sm" className="shadow-sm">
      <tbody>
        <tr>
          <th>Titre</th>
          <td>{title}</td>
        </tr>
        <tr>
          <td colSpan="2" className="text-center">
            <a href="http://vegas.jeuforum.fr" title="Accéder aux archives de Vegas">
              <FontAwesomeIcon icon="link" className="mr-1" />
              {link}
            </a>
          </td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{type}</td>
        </tr>
        <tr>
          <th>Période</th>
          <td>{period}</td>
        </tr>
        <tr>
          <th>Avatars</th>
          <td>{avatar}</td>
        </tr>
        <tr>
          <th>Pegi</th>
          <td>{pegi}+</td>
        </tr>
        <tr>
          <th>Nb. lignes</th>
          <td>{lines}</td>
        </tr>
        <tr>
          <th>Nb. membres</th>
          <td>{members}</td>
        </tr>
      </tbody>
    </Table>
  );
};

IdentiteTable.defaultProps = {
  avatar: "Réels",
  lines: 15,
  period: "Contemporaine",
  pegi: 18,
  type: "Forum d’intrigues"
};

IdentiteTable.propTypes = {
  avatar: PropTypes.string,
  lines: PropTypes.number,
  link: PropTypes.string.isRequired,
  pegi: PropTypes.number,
  period: PropTypes.string,
  title: PropTypes.string.isRequired,
  members: PropTypes.number.isRequired,
  type: PropTypes.string
};

export default IdentiteTable;
