import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem } from "reactstrap";
import { NavLink } from "react-router-dom";

const AfficheListGroup = (props) => {
  const { className } = props;

  return (
    <ListGroup className={className}>
      <ListGroupItem color={"dark"}>
        <strong>À l’affiche</strong>
      </ListGroupItem>
      <ListGroupItem tag={NavLink} to="/nephilim">
        Nephilim
      </ListGroupItem>
    </ListGroup>
  );
};

AfficheListGroup.defaultProps = {
  className: ""
};

AfficheListGroup.propTypes = {
  className: PropTypes.string.isRequired
};

export default AfficheListGroup;
