import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import ArchivesListGroup from "./ListGroup/ArchivesListGroup";
import AfficheListGroup from "./ListGroup/AfficheListGroup";

const ArchivesContainer = (props) => {
  const { children } = props;

  return (
    <Row>
      <Col md={4} lg={3}>
        <div className={"sticky"}>
          <AfficheListGroup className="mb-2" />
          <ArchivesListGroup />
          <p className="text-justify text-muted mt-3">
            <em>
              Les forums en « archives » ont malheureusement fermés leurs portes. Ils restent ouverts à la lecture.
            </em>
          </p>
        </div>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

ArchivesContainer.propTypes = {
  children: PropTypes.any.isRequired
};

export default ArchivesContainer;
