import React from "react";
import ArchivesContainer from "../component/ArchivesContainer";
import Figure from "../component/Figure";
import IdentiteTable from "../component/IdentiteTable";
import img from "../images/rome.png";
import { Col, Row } from "reactstrap";
import {Helmet} from "react-helmet";

const RomePage = () => {
  return (
    <ArchivesContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Rome : 3000 après Cæsar</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Rome : 3000 après Cæsar »" />
        <meta name="keywords" content="rome,3000,Cæsar,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="rome,3000,Cæsar,,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Rome : 3000 après Cæsar." />
        <meta property="og:description" content="Présentation du jeu de rôle « Rome : 3000 après Cæsar »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/rome" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/rome.jpg" />
      </Helmet>
      <h1>Rome</h1>
      <Row>
        <Col md={7}>
          <h2>En bref…</h2>
          <p className="text-justify">
            Le jeu de rôle « Rome : 3000 après Cæsar » se situe dans un avenir lointain. L'humanité a évolué et les
            sciences ont tant progressé que nous autres, contemporains, assimilerions leurs pouvoirs à de la magie pure.
            La technologie n'existe plus en temps que telle. Génétiquement modifiés et dopés aux nanotechnologies, les
            humains sont devenus plus forts, plus rapides et possèdent tous des dons incroyables. Le monde a changé.
            Après l'effondrement du libéralisme, après les grandes croisades, les religions polythéistes sont devenus le
            coeur du gouvernement. Chaque prière a son importance, car, lors des grandes festivités, les dieux les plus
            vénérés nomment leurs prêtres qui désignent à leur tour les sénateurs de l'empire Romain. Les enjeux des
            prières sont donc prépondérantes dans la vie romaine.
          </p>
        </Col>
        <Col>
          <Figure alt="Accueil de Rome" img={img}>
            Jeu de rôle par forum « Rome »
          </Figure>
        </Col>
      </Row>
      <h2>Synopsis</h2>
      <p className="text-justify">
        Le jeu de rôle « Rome : 3000 après Cæsar » se situe dans un avenir lointain. L'humanité a évolué et les sciences
        ont tant progressé que nous autres, contemporains, assimilerions leurs pouvoirs à de la magie pure. La
        technologie n'existe plus en temps que telle. Génétiquement modifiés et dopés aux nanotechnologies, les humains
        sont devenus plus forts, plus rapides et possèdent tous des dons incroyables. Le monde a changé. Après
        l'effondrement du libéralisme, après les grandes croisades, les religions polythéistes sont devenus le coeur du
        gouvernement. Chaque prière a son importance, car, lors des grandes festivités, les dieux les plus vénérés
        nomment leurs prêtres qui désignent à leur tour les sénateurs de l'empire Romain. Les enjeux des prières sont
        donc prépondérantes dans la vie romaine.
      </p>

      <p className="text-justify">
        Une autre espèce a évolué en parallèle : les androïdes, esclaves des êtres humains. Si ce ne sont les codes
        barres appliqués à leur poignet, ils ne peuvent physiquement pas être distingués des humains. Leur statut est
        pour le moins ambiguë. Certains humains veulent tous les détruire, d'autres les utilisent comme esclaves, voire
        esclaves sexuels et d'autres enfin souhaitent les libérer de ce joug. Plusieurs courants de pensées s'affrontent
        donc. Mais en réalité, ce débat ne devrait pas avoir lieu. À leur conception initiale, les androïdes ont été
        pourvus de plots mentaux qui les contraignent à obéir à leur maître avec la plus grande dévotion. Ils ne
        devraient même pas être capable de prétendre à leur liberté. Mais l'intelligence artificielle de certains
        androïdes a permis à certains de contourner ce plot, de le rendre inefficace. Ces androïdes ne sont plus obligés
        de servir. Mais ils se cachent. De tels androïdes sont reformatés, purgés de leur conscience quand ils sont
        découverts.
      </p>

      <p className="text-justify">
        Rome est une ville perdue, derrière les remparts de la cité des créatures monstrueuses empêchent les romains de
        découvrir ce qu'il est advenu des autres civilisations. L'ancienne culture romaine a refait surface. Les romains
        vivent de l'agriculture, de la pêche, profitent des jeux au Colisée et se sont tournés vers une religion
        polythéiste tolérante. Quatre dieux antiques y sont vénérés. Jupiter, père des dieux, et ses prêtres souhaitent
        que la situation reste ainsi. Minerve, déesse de la guerre, souhaite que les soldats soient entraînés et qu'une
        nouvelle armée soit renforcée pour aller détruire ces créatures entourant la ville. Pluton, gardien des portes
        de l'Enfer, est devenu le gardien de la cité. Lui et ses hommes protègent la ville et ses remparts. Quant à
        Vénus, déesse luxuriante, elle a la réputation de n'accorder aucun intérêt à la politique et préfère jouir de la
        vie à Rome.
      </p>
      <Row>
        <Col md={5}>
          <h2>Fiche d’identité</h2>
          <IdentiteTable
            link={"http://rome.jeuforum.fr"}
            title={"Rome : 3000 après Cæsar"}
            members={50}
            period="Futuriste"
          />
        </Col>
        <Col>
          <h2>Et vous ? Qui incarnerez-vous ?</h2>
          <ul>
            <li>Un prêtre de Vénus ?</li>
            <li>Un soldat de Minerve ?</li>
            <li>Un androïde sexuel totalement asservi au bien-être de sa Domina ?</li>
            <li>Une androïde rebelle désirant faire reconnaître le droit à la prière pour les siens ?</li>
          </ul>
        </Col>
      </Row>
    </ArchivesContainer>
  );
};

export default RomePage;
