import React from "react";
import ArchivesContainer from "../component/ArchivesContainer";
import { Col, Row } from "reactstrap";
import Figure from "../component/Figure";
import img from "../images/orgone.jpg";
import IdentiteTable from "../component/IdentiteTable";
import {Helmet} from "react-helmet";

const OrgonePage = () => {
  return (
    <ArchivesContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Orgone</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Orgone »" />
        <meta name="keywords" content="Orgone,nephilim,elohim,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="Orgone,nephilim,elohim,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Orgone." />
        <meta property="og:description" content="Présentation du jeu de rôle « Orgone »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/orgone" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/orgone.jpg" />
      </Helmet>
      <h1>Orgone, société « dynamique et innovante »</h1>
      <Row>
        <Col lg={7}>
          <h2>En bref…</h2>
          <p className="text-justify">
            Le jeu de rôle « Orgone » se déroule dans une entreprise aux nombreux secrets. Salaire motivant, personnel
            d’une beauté encore plus intéressante, un projet décrit comme secret et innovant. Difficile de résister,
            n’est-ce pas ? Que se cache derrière cette entreprise ? C’était là tout l’enjeu de ce forum d’intrigue.
          </p>
        </Col>
        <Col>
          <Figure alt="Accueil d’Orgone" img={img}>
            Jeu de rôle par forum « Orgone »
          </Figure>
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <h2>Fiche d’identité</h2>
          <IdentiteTable link={"https://orgone.jeuforum.fr"} title={"Orgone"} members={15} />
        </Col>
        <Col>
          <h2>Synopsis</h2>
          <p className="text-justify">
            Une société française spécialisée dans les effets spéciaux post-production s’est installée sur la péninsule
            de Miami. Pour mieux s’implanter, elle a acheté quelques étages d’un building. Jusque là, l’affaire serait
            banale et passerait inaperçue si quelques communiqués n’étaient parus dans la presse. Les annonces
            présentent des recherches sur un projet à même de révolutionner l’univers des jeux vidéos, des réseaux
            sociaux et de la cinématographie.
          </p>
          <p className="text-justify">
            Que vous ayez déposé un CV ou non, un jour, un centre d’appel vient de vous contacter pour vous proposer un
            entretien d’embauche dans les locaux d’Orgone. Motivé par la nécessité de travailler, par la curiosité ou
            par le salaire intéressant proposé, vous avez acceptez le rendez-vous.
          </p>
          <p className="text-justify">
            Et aujourd’hui, vous vous trouvez au pied de la tour, prêt à passer ce fameux entretien. Vous vous êtes
            renseignés sur cette société, mais rien ne filtre. Tout ce que vous avez appris, c’est que les candidats
            retenus par leur service des ressources humaines ont tous acceptés le job qu’on leur proposé, y compris ceux
            qui avaient déjà un emploi intéressant.
          </p>
        </Col>
      </Row>
    </ArchivesContainer>
  );
};

export default OrgonePage;
