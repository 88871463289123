import React from "react";
import img from "../images/vegas.jpg";
import { Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import IdentiteTable from "../component/IdentiteTable";
import Figure from "../component/Figure";
import ArchivesContainer from "../component/ArchivesContainer";
import NephilimTooltip from "../component/Tooltip/NephilimTooltip";
import {Helmet} from "react-helmet";

const VegasPage = () => {
  return (
    <ArchivesContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Vegas</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Vegas »" />
        <meta name="keywords" content="vegas,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="vegas,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Vegas." />
        <meta property="og:description" content="Présentation du jeu de rôle « Vegas »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/vegas" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/vegas.jpg" />
      </Helmet>
      <h1>Vegas 2013</h1>
      <Figure alt="Affiche de Vegas 2013" img={img}>
        Jeu de rôle par forum « Vegas »
      </Figure>
      <Row>
        <Col lg={6}>
          <h2>En bref…</h2>
          <p className="text-justify">
            Présent sur la toile pendant quatre ans, ce forum suit l’épopée de <NephilimTooltip />, des anges déchus
            luttant pour leur survie sur Terre. Loin des clichés gentils anges et méchants démons, ce contexte se
            dévoile tout en nuance de gris. Mais ne vous y méprenez pas, ce n’était pas un forum maîtres-esclaves ou
            chaque couple joue dans son coin. Les administrateurs vous offraient un contexte en constante évolution,
            rempli d’intrigues dans lesquelles vos personnages sont catapultés bon gré, mal gré.
          </p>
          <p className="text-justify">
            Le forum a fermé, car un événement a bouleversé le background. Les <NephilimTooltip /> ont été découverts
            par l’Humanité. Le forum a donc migré vers un nouveau site :{" "}
            <NavLink to="/nephilim">Nephilim Révélation</NavLink>.
          </p>
        </Col>
        <Col>
          <h2>Fiche d’identité</h2>
          <IdentiteTable link="https://vegas.jeuforum.fr" members={30} title="Vegas" />
        </Col>
      </Row>
      <h2>Les personnages et les possibilités du jeu</h2>
      <p className="text-justify">
        En découvrant ce jeu de rôle, on est surpris par la richesse du background. Il puise ses racines dans la bible,
        mais aussi dans différents jeux de rôles, comme <NephilimTooltip />, le jeu de l’occulte contemporain. Les
        Elohim n’ont pas la possibilité de descendre sur Terre sans s’incarner dans un corps humain. Et contrairement à
        Jonathan Smith de la série «Les routes du Paradis», les anges ne viennent pour aider les êtres humains, mais
        pour chasser leurs frères déchus, les <NephilimTooltip />. Déchus par Dieu pour être descendus sur Terre et
        avoir souillé leur nature angélique, les <NephilimTooltip /> ont perdu leurs ailes blanches immaculés et la
        possibilité de revenir en Eden. Alors, ils compensent et tirent leur puissance démoniaque de l’Orgone, l’énergie
        sexuelle. Le but de cet article n’étant pas de vous dévoiler tout le contexte, retenez surtout que l’intrigue
        principale se déroule à Vegas, cité du vice et de la luxure ! Comme sur la plupart des forums de jeu de rôles,
        cela permet de confiner les personnages dans un lieu clos, de mêler les intrigues et de multiplier leurs
        rencontres.
      </p>
      <p className="text-justify">
        Quant à la luxure, c’est un des thèmes forts de ce jeu de rôle, les <NephilimTooltip /> se rapprochent donc plus
        des succubes et des incubes. Incroyablement beaux, ils séduisent l’humanité pour non pas voler, mais bien se
        nourrir de leur plaisir. Âmes sensibles, vous pourrez donc lire tous les RPs écrits. Mais la thématique reste
        importante, car sans Orgone, les <NephilimTooltip /> perdent leur pouvoir et deviennent aussi frêles que les
        malheureux humains pris dans la tourmente de cette guerre. Du coup, les Templiers ont une chance de lutter. Les
        jouer n’est pas impossible puisque les <NephilimTooltip /> sont puissants, mais pas indéboulonnables. De ce
        contexte ressort donc la possibilité à chaque personnages d’avoir une chance quelque soit le clan, la race et la
        voie choisis.
      </p>
      <p className="text-justify">
        Il reste encore une classe très particulière : Les anciens simulacres. Lorsqu’un ange prend possession d’un
        corps, il le possède, l’humain devient donc spectateur de son propre corps, désormais mu par l’Ange. Certains
        anges sont chassés de l’hôte humain. L’humain recouvre alors sa liberté. S’il survit aux premières heures de
        cette libération, il découvrira qu’il a développé des dons de prescience. C’est encore un autre concept qui
        démontre la richesse de ce background.
      </p>
      <Row>
        <Col lg={5}>
          <h2>Notre avis</h2>
          <p className="text-justify">
            l m’est difficile de donner un avis puisque je suis attaché à ce jeu et que je m’y suis inscrit comme
            joueur. Si je devais citer le point essentiel, ce seraient les intrigues. Au travers d’articles de presses,
            de messages sur vos téléphones, de petits MPs, les deux administrateurs créent des événements auxquels vous
            vous retrouvez mêler. On retrouve donc les grandes lignes des jeux de rôles sur table avec un MJ, pas
            forcément sadique, qui vous lance dans une histoire palpitante.
          </p>
        </Col>
        <Col>
          <h2>Qui incarnez-vous ?</h2>
          <ul>
            <li>
              Un <NephilimTooltip />, une puissante succube, un puissant incube,
            </li>
            <li>
              un Templier ayant la difficile tâche d’infiltrer Vegas et de piéger les <NephilimTooltip />,
            </li>
            <li>
              un Initié aidant généralement les <NephilimTooltip />,
            </li>
            <li>un ancien simulacre, ayant retrouvé sa liberté,</li>
            <li>
              ou au contraire un profane, un humain totalement inconscient de la guerre occulte qui se trame à Vegas.
            </li>
          </ul>
        </Col>
      </Row>
    </ArchivesContainer>
  );
};

export default VegasPage;
