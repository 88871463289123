import React from "react";
import { Button, Col, Row } from "reactstrap";
import ArchivesListGroup from "../component/ListGroup/ArchivesListGroup";
import AfficheListGroup from "../component/ListGroup/AfficheListGroup";
import Figure from "../component/Figure";
import img from "../images/nephilim.jpg";
import { NavLink } from "react-router-dom";

const NephilimPage = () => {
  return (
    <Row>
      <Col md={4} lg={3}>
        <div className={"sticky"}>
          <AfficheListGroup className="mb-2" />
          <ArchivesListGroup />
        </div>
      </Col>
      <Col>
        <h1>
          Jeuforum.fr
          <br /> <small>Plateforme d’hébergement de jeu de rôle par forum</small>
        </h1>
        <p className="text-justify">
          JeuForum.fr est une plateforme d’hébergement de jeu de rôle par forum. Sans publicité, gratuite, elle vit
          grâce aux dons de deux joueurs, la première infographiste, le second geek.
        </p>
        <p className="text-justify">Dans le menu, vous trouverez deux volets : les archives et l’affiche.</p>
        <Row>
          <Col lg={7}>
            <h2>En tête d’affiche</h2>
            <p className="text-justify">
              En tête d’affiche, nous sommes fiers de vous présenter « 
              <NavLink tag={"a"} to="/nephilim" title="Nephilim">
                Nephilim, la Révélation
              </NavLink>
               ».
            </p>
            <div className="text-right">
              <Button to="/nephilim" tag={NavLink} color="primary">
                En savoir plus&hellip;
              </Button>
            </div>
          </Col>
          <Col>
            <Figure alt="Accueil de « Nephilim — La Révélation »" img={img}>
              Tête d'affiche « Nephilim — La Révélation »
            </Figure>
          </Col>
        </Row>
        <h2>Les archives</h2>
        <p className="text-justify">
          Elles présentent les jeux de rôle terminés. Ils peuvent être arrivés à leur terme, car les intrigues ont été
          résolues comme&nbsp;
          <NavLink tag={"a"} to="/archives/rome" title="Rome, 3000 après Caesar">
            Rome
          </NavLink>
          , soit ils ont subi une forte évolution et les administrateurs ont décidé d'ouvrir un nouveau forum. Ce fut le
          cas de&nbsp;
          <NavLink tag={"a"} to="/archives/vegas" title="Vegas">
            Vegas
          </NavLink>
          . À la suite d’une intrigue, l’Humanité a découvert l’existence des Nephilim. Les administrateurs ont alors
          ouvert&nbsp;
          <NavLink tag={"a"} to="/nephilim" title="Nephilim">
            Nephilim, la Révélation
          </NavLink>
          . Et malheureusement, d’autres forums ont fermé leur porte faute de succès, ce fut le cas de{" "}
          <NavLink tag={"a"} to="/archives/2666" title="2666, l’exode">
            2666
          </NavLink>
          .
        </p>
      </Col>
    </Row>
  );
};

export default NephilimPage;
