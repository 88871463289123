import React from "react";
import ArchivesContainer from "../component/ArchivesContainer";
import { Col, Row } from "reactstrap";
import IdentiteTable from "../component/IdentiteTable";
import Figure from "../component/Figure";
import img from "../images/2666.jpg";
import {Helmet} from "react-helmet";

const DeuxMilPage = () => {
  return (
    <ArchivesContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">2666, l’exode</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « 2666, l’exode »" />
        <meta name="keywords" content="2666,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="2666,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : 2666, l’exode." />
        <meta property="og:description" content="Présentation du jeu de rôle « 2666, l’exode »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/2666" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/2666.jpg" />
      </Helmet>
      <h1>2666</h1>
      <Row>
        <Col lg={7}>
          <h2>En bref&hellip;</h2>
          <p className="text-justify">
            Ce jeu de science-fiction reprenait l’univers des Nephilim. Dans une flotte de vaisseaux spatiaux, Nephilim
            et humains tentent de trouver une nouvelle Terre, tel Ulysse en Méditerranée.
          </p>
          <p className="text-justify">
            Ce jeu offrait également la possibilité de créer des contacts, des sortes de seconds rôles. Malgré les aides
            de jeu offertes pour créer son personnage plus rapidement, ce jeu n’a pas fonctionné, car les contacts
            devaient être créés dès le début. Il ne permettait pas facilement de commencer.
          </p>
        </Col>
        <Col>
          <Figure alt="Accueil de 2666" img={img}>
            Accueil de « 2666 »
          </Figure>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <h2>Fiche d’identité</h2>
          <IdentiteTable
            link="https://2666.jeuforum.fr"
            title="2666"
            members={12}
            period={"Futuriste"}
            type={"Science-Fiction"}
          />
        </Col>
        <Col>
          <h2>L’exode</h2>
          <p className="text-justify">
            Les Nephilim, accompagnés d'humains, ont été contraints de fuir précipitamment le système solaire. Le « San
            Djinn Bar » a beau être le vaisseau le plus puissant de l'armada Nephilim, il fait désormais des bonds
            spatiaux quand cela lui chante. Abandonner le «San Djinn Bar» serait une hérésie tant sa technologie est
            indispensable aux survivants. Alors, quand il bondit, les capitaines des navires voisins n'ont d'autres
            choix que de le suivre dans les trois minutes. S'ils ne bondissent pas durant ce court créneau, ils ne
            rejoindront jamais la flotte, car il est impossible de choisir sa destination. Dans ces conditions,
            généralement, toutes les navettes qui assurent les voyages d'un vaisseau à l'autre et les petits vaisseaux
            de combat, appontent en urgence sur les quais des transporteurs.
          </p>
          <p className="text-justify">
            2666 suit l'épopée du «San Djinn Barr» et de la centaine de vaisseaux qui l'accompagnent. Ce jeu de rôle par
            forum vous permettra d'incarner un personnage principal, mais également ses contacts. Notre assistante,
            Lazer sera là pour vous guider dans cette étape novatrice.
          </p>
          <p className="text-justify">
            Le jeu se déroulait bond par bond. À chaque bond effectué, une nouvelle intrigue commençait et plongeait les
            personnages dans la tourmente et la passion de rencontres improbables.
          </p>
        </Col>
      </Row>
    </ArchivesContainer>
  );
};

export default DeuxMilPage;
