import React from "react";
import GlobalTooltip from "./GlobalTooltip";

const ElohimTooltip = () => {
  return (
    <GlobalTooltip title={"Elohim"}>
      Un ange descendu sur Terre. Pour protéger sa pureté des péchers humains, les Anges prennent possession d’un être
      humain.
    </GlobalTooltip>
  );
};

export default ElohimTooltip;
