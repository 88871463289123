import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class GlobalTooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.id =
      "id-" +
      Math.random()
        .toString(36)
        .substring(9);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { children, title } = this.props;
    return (
      <span>
        <a id={this.id} className="link">
          {title}
        </a>
        <Tooltip placement="bootom" isOpen={this.state.tooltipOpen} toggle={this.toggle} target={this.id}>
          {children}
        </Tooltip>
      </span>
    );
  }
}

GlobalTooltip.propTypes = {
  children: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default GlobalTooltip;
