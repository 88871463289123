import React from "react";
import AvatarContainer from "../component/ArchivesContainer";
import { Col, Row } from "reactstrap";
import Figure from "../component/Figure";
import img from "../images/elevation.jpg";
import IdentiteTable from "../component/IdentiteTable";
import {Helmet} from "react-helmet";

const ElevationPage = () => {
  return (
    <AvatarContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Vegas-Elevation</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Vegas-Elevation »" />
        <meta name="keywords" content="Vegas-Elevation,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="Vegas-Elevation,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Vegas-Elevation." />
        <meta property="og:description" content="Présentation du jeu de rôle « Vegas-Elevation »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/vegas-elevation" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/vegas-elevation.jpg" />
      </Helmet>
      <h1>Vegas-Elevation</h1>
      <Figure alt={"Accueil de Vegas-Elevation"} img={img}>
        Jeu de rôle « Vegas-Elevation »
      </Figure>
      <Row>
        <Col lg={6}>
          <h2>En bref</h2>
          <p className="text-justify">
            Vegas-Elevation est la suite de Gehinnom après la destruction de l'île. Les Nephilim ont pris la ville de
            Vegas, au Nevada. Drogue, luxe, prostitution, mais aussi police et mairie, leur emprise est totale. Il sera
            difficile pour les humains de s’en sortir. Les Templiers, grâce aux Anges, souhaitent l’Humanité à s’en
            sortir. Ils peuvent compter sur un allié de poids : la discorde. Malgré les apparences, les Nephilim ne sont
            pas si unis qu’il n’y parait. Les guerres intestines de clan n’ont pas disparu.
          </p>
        </Col>
        <Col>
          <h2>Fiche d’identité</h2>
          <IdentiteTable link={"https://archives-vegas.jeuforum.fr"} title="Vegas-Elevation" members={40} />
        </Col>
      </Row>
      <h2>Introduction</h2>
      <blockquote className="blockquote font-weight-light font-italic font-small">
        <p>04 février 2010 - Pitié ! Que quelqu'un m'entende...</p>
        <p>Je dois me dépêcher, je n'aurai peut être pas le temps de finir...</p>
        <p>
          Ils... Ils vont me retrouver. Je crois que je suis en train de devenir folle. Je suis couverte de sueur, de
          terre et... de sang... Je ne sais pas ce qu'ils me veulent. Je suis épuisée, je n'en peux plus de courir... Je
          ne sais pas pourquoi ils sont là, à Las Vegas, mais ils contrôleront bientôt toute la ville ! Mes vêtements
          sont sales, et je ne suis pas revenue ici depuis 3 jours. Il faut que je me change. Mais je sais qu'ils me
          voient... Ils ont des pouvoirs.
        </p>
        <p>
          L'un d'eux... Il me suit à la trace depuis samedi dernier... Il veut me tuer. Il n'est pas loin. Je regarde
          par dessus mon épaule et à chaque fois, je m'attends à le voir, juste là, debout contre mon rideau. Mon
          Dieu... Je suis certaine qu'il veut me tuer.
        </p>
        <p>
          Ils... ils sont plusieurs. Ils se déplacent en clan. Ce sont des monstres. Ils entrent dans ma tête et me
          donnent envie de faire... des choses... des choses horribles. Je n'arrive plus à m'arrêter de pleurer. Mes
          joues sont couvertes de rimmel séché, mélangé à la crasse et... personne ne peut m'aider ! Oh non, mon Dieu !!
          Je recommence à vibrer, à sentir cette chaleur... Non... !! Ils... Ils sont là ! Je sais qu'ils sont là, sur
          le toit, dans mon garage ou juste derrière ma porte... Ils veulent me rendre dingue ! Ils veulent me tuer ! Ou
          me violer... Ils réclament mon corps et mes gémissements comme s'ils s'en nourrissaient. Ce sont des Démons...
        </p>
        <p>
          Qui que vous soyez, si vous lisez cette lettre : ne venez pas à Las Vegas ! Fuyez, tant que vous le pouvez !
          Bientôt, cette ville sera un Enfer... Je le sais. Je le sens... Les autorités, et même la Mafia ne seront que
          des spectateurs désarmés. Qui que vous soyez... Si vous décidez de venir à Vegas, ce jour-là, votre destin
          sera scellé.
        </p>
        <footer className="blockquote-footer text-right">
          Vanessa Lannir, Humaine-Esclave morte dix jours après la prise de Las Vegas ...
        </footer>
      </blockquote>
    </AvatarContainer>
  );
};

export default ElevationPage;
