import React from "react";
import GlobalTooltip from "./GlobalTooltip";

const NephilimTooltip = () => {
  return (
    <GlobalTooltip title={"Nephilim"}>
      Descendant d’un Ange Déchu et d’un humain, la mythologie les décrivait comme des demi-dieux voire des incubes ou
      des succubes.
    </GlobalTooltip>
  );
};

export default NephilimTooltip;
