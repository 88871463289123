import React from "react";
import { Col, Row } from "reactstrap";
import ArchivesListGroup from "../component/ListGroup/ArchivesListGroup";
import AfficheListGroup from "../component/ListGroup/AfficheListGroup";
import Figure from "../component/Figure";
import img from "../images/nephilim.jpg";
import IdentiteTable from "../component/IdentiteTable";
import NephilimTooltip from "../component/Tooltip/NephilimTooltip";
import ElohimTooltip from "../component/Tooltip/ElohimTooltip";
import {Helmet} from "react-helmet";

const NephilimPage = () => {
  return (
    <div>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Nephilim - La Révélation</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Nephilim - La Révélation »" />
        <meta name="keywords" content="révélation,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="révélation,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Nephilim - La Révélation." />
        <meta property="og:description" content="Présentation du jeu de rôle « Nephilim - La Révélation »" />
        <meta property="og:url" content="https://www.jeuforum.fr/nephilim" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/nephilim.jpg" />
      </Helmet>
    <Row>
      <Col md={4} lg={3}>
        <div className={"sticky"}>
          <AfficheListGroup className="mb-2" />
          <ArchivesListGroup />
        </div>
      </Col>
      <Col>
        <h1>Nephilim — La Révélation</h1>
        <Row>
          <Col lg={7}>
            <h2>En bref&hellip;</h2>
            <p className="text-justify">
              L’Humanité a découvert ce qui se cachait derrière la trame visible de l'Histoire : Les <ElohimTooltip />{" "}
              et leurs frères déchus, les <NephilimTooltip />, vivent parmi eux. Ce jeu de rôle gravite autour de deux
              grandes villes : Paris, cité des Lumières et Las Vegas, cité du vice et de la Luxure.
            </p>
            <p className="text-justify">
              Ce dernier volet de l’histoire des <NephilimTooltip /> permet donc aux joueurs d’évoluer dans un monde
              bouleversé par une telle découverte. Comment l’Humanité peut réagir à une telle révélation ? C’est à vous
              de l’écrire !
            </p>
          </Col>
          <Col>
            <Figure alt="Accueil de « Nephilim — La Révélation »" img={img}>
              Jeu de rôle « Nephilim — La Révélation »
            </Figure>
          </Col>
        </Row>
        <h2>Synopsis</h2>
        <p className="text-justify">
          Vous savez désormais que les <NephilimTooltip /> et les <ElohimTooltip /> existent. Les Infiltrés et les
          Templiers ne filtrent plus les informations et vous savez que des <NephilimTooltip /> tiennent la ville de
          Vegas, que les <ElohimTooltip /> perdent du terrain à Paris, que des foyers angéliques et Nephilim se forment
          dans les grandes villes de la Terre. Que faites-vous ?
        </p>
        <p className="text-justify">
          Si vous avez déjà participé à un des forums précédant Nephilim ou si vous avez choisi la lecture complète du
          background, vous en savez un peu plus sur les <NephilimTooltip /> et les Elohim. Sinon, pour vous, les
          Nephilim sont des démons, des succubes magnifiques et particulièrement séduisant. Leur magnifique ambassadrice
          se nomme Vesta. Elle promet une vie paisible et harmonieuse, elle a déjà construit un hôpital extraordinaire à
          Vegas. Il fait déchanter bien des détracteurs, car ceux qui s'y rendent ressortent bien souvent guéri. En plus
          d'un tourisme de loisir et d'un tourisme sexuel, un tourisme de santé s'est créé dans la ville de Vegas.
        </p>
        <p className="text-justify">
          Oui j'ai parlé d'un tourisme sexuel. Qu'en est-il ? Les <NephilimTooltip /> sont des démons magnifiques.
          Oubliez les mannequins, les canons de beauté humain. En plus de leur beauté physique, les <NephilimTooltip />{" "}
          ont un charisme extraordinaire. Alors, de nombreux humains tentent leur chance. Ils viennent à Vegas dans le
          but de trouver des Nephilim, de les côtoyer, de les séduire ou de se faire séduire. Même si les{" "}
          <NephilimTooltip /> sont de moins en moins nombreux à Vegas, ce tourisme ne baisse pas. Pourquoi ? Les
          Nephilim tirent leur essence dans l'Orgone, une énergie brute délivrée uniquement par les humains lors de
          leurs rapports sexuels.
        </p>
        <Row>
          <Col lg={5}>
            <h2>Fiche d’identité</h2>
            <IdentiteTable link={"https://nephilim.jeuforum.fr"} title={"Nephilim — La Révélation"} members={30} />
          </Col>
          <Col>
            <h2>Et vous qui incarnerez-vous ?</h2>
            <ul>
              <li>
                Un <NephilimTooltip />, descendant d’un Ange déchu et d’un humain,
              </li>
              <li>un Ange, incarné dans un être humain,</li>
              <li>
                un Templier, un humain au service des <ElohimTooltip />,
              </li>
              <li>un Initié, un humain qui connaissait l’existence des Nephilim bien avant la révélation,</li>
              <li>ou un de ces humains dont la vie vient d’être chamboulé par cette révélation ?</li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
    </div>
  );
};

export default NephilimPage;
