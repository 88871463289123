import React from "react";
import AvatarContainer from "../component/ArchivesContainer";
import { Col, Row } from "reactstrap";
import Figure from "../component/Figure";
import gehinnom from "../images/gehinnom.jpg";
import IdentiteTable from "../component/IdentiteTable";
import {Helmet} from "react-helmet";

const GehinnomPage = () => {
  return (
    <AvatarContainer>
      <Helmet titleTemplate="%s | JeuForum.fr">
        <title itemProp="name" lang="fr">Gehinnom</title>
        <meta name="robots" content="all, index, follow" />
        <meta name="description" content="Présentation du jeu de rôle « Gehinnom »" />
        <meta name="keywords" content="gehinnom,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="news_keywords" content="gehinnom,exode,nephilim,elohim,science-fiction,jeu de rôles,forum,jeu par forum" />
        <meta name="revisit-after" content="30 days" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Jeuforum.fr : Gehinnom." />
        <meta property="og:description" content="Présentation du jeu de rôle « Gehinnom »" />
        <meta property="og:url" content="https://www.jeuforum.fr/archives/gehinnom" />
        <meta property="og:site_name" content="JeuForum.fr" />
        <meta property="og:image" content="https://www.jeuforum.fr/gehinnom.jpg" />
      </Helmet>
      <h1>Gehinnom</h1>
      <Row>
        <Col lg={7}>
          <h2>En bref</h2>
          <p className="text-justify">
            Notre premier jeu de rôle par forum. Fondé en 2009, son histoire évolue autour d’une île paradisiaque à
            l’abri des regards. Les Nephilim y règnent en maîtres et assouvissent leur désir sur quelques humains
            réduits en esclavages. Parmi ses anges déchus, Harahel décide d’aider les humains à se libérer de leur joug
            sous le regard bienveillant de Lilith une Ange déchue.
          </p>
        </Col>
        <Col>
          <Figure alt="Accueil de Gehinnom" img={gehinnom}>
            Notre premier jeu de rôle : « Gehinnom »
          </Figure>
        </Col>
      </Row>
      <h2>Présentation</h2>
      <p className="text-justify">
        L’histoire de l’humanité, tous continents, peuples et religions confondues, porte les traces d’une croyance
        éternelle en un pouvoir magique de la sexualité, cherchant à la maîtriser, à la dominer : Magie Sexuelle,
        Tantrisme, Rites Païens, Sorcellerie. Certaines même de nos religions la craignent tellement, qu’elles
        instaurèrent des commandements répressifs, visant à limiter l’usage du sexe et donc la libération et la
        manifestation de cette énergie dans l’Univers, probablement l’une des plus puissante : L’Orgone. Omniprésente,
        elle est tapie en chaque être vivant, attendant d’être révélée, déchaînée. Elle peut guérir ou blesser, agir sur
        des choses à distance, créer, nourrir et amplifier sortilèges et actes magiques. Elle vit dans le désir, la
        sensualité, la sexualité et se manifeste dans l’orgasme.
      </p>
      <p className="text-justify">
        Ce pouvoir, fondement même de la création, et son utilisation, est bien antérieur à l’Homme, qui n’a fait que
        tenter d’imiter des êtres magiques, maudits, si anciens que leur origine se perd dans les éons, les Nephilim :
        Adam, Lilith, Samaël et bien d’autres encore. La révolte de Lilith et de Samaël, entraîna celle d’autres
        Nephilim, qui quittèrent l’ Eden afin de vivre parmi les mortels. Utilisant l’ Orgone pour devenir les égaux d’
        Elohah, ils plongèrent la Création dans la corruption. Le Créateur dans sa fureur divine, envoya sur Terre ses
        Elohim, les derniers Nephilim restés fidèles, afin de combattre et d’emprisonner leurs frères immortels, au sein
        d’une prison magique entre Eden et Terre, et provoqua le Déluge Originel, purifiant la Terre du mal.
      </p>
      <p className="text-justify">
        Lilith et Samaël parvinrent à s’enfuir et continuèrent d’engendrer, avec des mortels, leurs enfants, les
        Lilioth. Êtres Surnaturels, ils tirent leurs pouvoirs et leur puissance de l’Orgone comme leurs parents
        Nephilim. Leur existence même est liée à Elle. Comme un vampire a besoin de sang pour survivre, les Lilioth ont
        besoin d’énergie sexuelle pour perdurer. Sans Elle, ils s’affaiblissent et disparaissent dans le néant pour
        l’éternité.
      </p>
      <p className="text-justify">
        S’accouplant avec des fils et des filles d’Ève, les Lilioth engendrèrent à leur tour, des êtres hybrides
        inférieurs, les Lilim.
      </p>
      <p className="text-justify">
        Le monde sombrant à nouveau dans la perversion, Elohah sacrifia son Elohim préféré, Yeshua, jetant la base d’une
        nouvelle religion, le Christiannisme. Son supplice, acte magique ordonné par le Créateur, plongea Lilith et
        Samaël dans un sommeil profond, laissant leurs créatures seules dans un monde hostile, pourchassées par les
        Elohim, et leurs agents humains. Certains d’entre eux trouvèrent refuge à Gehinnom, lieu magique et mystique où
        les corps de leurs parents reposaient, tandis que les plus courageux rassemblaient au sein du monde des hommes,
        l’ Orgone nécessaire à leur réveil, œuvrant dans l’ombre et manipulant l’humanité pour affaiblir le pouvoir du
        Créateur. Contrairement à la Gehenne biblique, Gehinnom est une ile privée des Baléares, au large de l’Espagne.
      </p>
      <p className="text-justify">
        Aujourd’hui, en 2001 , Lilith et Samaël, sont réveillés et canalisent une partie de l’Orgone que leurs enfants
        emmagasinent afin d’accomplir la Tâche Sacrée de libérer leurs frères Nephilim, emprisonnés par Elohah et d’à
        nouveau régner sur l’Humanité…
      </p>
      <Row>
        <Col>
          <h2>Fiche d’identité</h2>
          <IdentiteTable link="https://gehinnom.jeuforum.fr" title="Gehinnom" members={25} type="Forum maître-esclave" />
        </Col>
        <Col lg={7}>
          <h2>Qui incarnerez-vous ?</h2>
          <ul className="text-justify">
            <li>un Lilioth, fille et fils de Samaël et de Lilith, accouplés avec des humains,</li>
            <li>un Lilim, être hybride fruit de l’accouplement d’un Lilioth avec un être humain,</li>
            <li>un humain, esclave ou serviteur.</li>
          </ul>
        </Col>
      </Row>
    </AvatarContainer>
  );
};

export default GehinnomPage;
