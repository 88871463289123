import React from 'react';
import PropTypes from 'prop-types';

const Figure = props => {
  const {alt, children, img} = props;

  return (
    <figure className="figure">
      <img src={img} alt={alt} className="figure-img shadow-sm img-fluid rounded img-thumbnail" />
      <figcaption className="figure-caption text-center">{children}</figcaption>
    </figure>

  );
};

Figure.propTypes = {
  alt: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default Figure;
