import React, { Component } from "react";
import DeuxMilPage from "./page/DeuxMilPage";
import ElevationPage from "./page/ElevationPage";
import GehinnomPage from "./page/GehinnomPage";
import HomePage from "./page/HomePage";
import MyNavbar from "./component/MyNavbar";
import NephilimPage from "./page/NephilimPage";
import NotFoundPage from "./page/NotFoundPage";
import OrgonePage from "./page/OrgonePage";
import RomePage from "./page/RomePage";
import VegasPage from "./page/VegasPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import {Helmet} from "react-helmet";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Helmet titleTemplate="%s | JeuForum.fr">
            <html lang="fr"/>
            <title itemProp="name" lang="fr">Plateforme d’hébergement de forums</title>
            <meta name="robots" content="all, index, follow" />
            <meta name="description" content="Plateforme d’hébergement de jeu de rôles par forum." />
            <meta name="author" content="Alexandre Tranchant" />
            <meta name="copyright" content="Alexandre Tranchant" />
            <meta name="keywords" content="Plateforme,hébergement,jeu de rôles,forum,jeu par forum" />
            <meta name="news_keywords" content="Plateforme,hébergement,jeu de rôles,forum,jeu par forum" />
            <meta name="revisit-after" content="30 days" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Jeuforum.fr, jeu de rôles par forum." />
            <meta property="og:description" content="Plateforme d’hébergement de jeu de rôles par forum." />
            <meta property="og:url" content="https://www.jeuforum.fr" />
            <meta property="og:site_name" content="JeuForum.fr" />
            <meta property="og:image" content="https://www.jeuforum.fr/jeuforum.jpg" />
            </Helmet>
          <MyNavbar />
          <Container className={"mt-4"}>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/nephilim" component={NephilimPage} />
              <Route path="/archives/2666" component={DeuxMilPage} />
              <Route path="/archives/orgone" component={OrgonePage} />
              <Route path="/archives/rome" component={RomePage} />
              <Route path="/archives/vegas" component={VegasPage} />
              <Route path="/archives/vegas-elevation" component={ElevationPage} />
              <Route path="/archives/gehinnom" component={GehinnomPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
